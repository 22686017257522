import React from 'react';
import "./Ourteam.css";
import { Line } from '../components';

const Ourteam = () => {
  return (
    <div className="ourteam">

      <div className="ourteam_title_container">
        <div className="ourteam_title">
          our leaders
        </div>
        <div className="ourteam_description">
          As the core of International Buddy, our volunteers make everything we do possible. Below are some of our organizational leaders, who lead our volunteers in everything we do.
        </div>
      </div>
      <div className="ourteam_content_container">
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FSteven%20Gao.JPG?alt=media&token=15a9e3e4-6723-4ed1-99d7-90e4175deb59" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Steven Gao<br />
          President
          </div>
          <div className="ourteam_selfintro">
          Hi! My name is Steven Gao, a senior at Mercer Island High School for the 2024 – 2025 school year. I am excited to serve as the president of International Buddy (IB) this year. Since first joining in my freshmen year, I’ve had the wonderful opportunities of contributing to a diverse and fun community while forming connections with our volunteers and buddies. As president, I am dedicated to further our impact and development through the enhancement of our fundraising, outreach, and organizational initiatives, as well as continuing to foster an inclusive and supportive environment for everyone in IB.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FYiran%20(Ryan)%20Pang.JPG?alt=media&token=0fb9c991-20bc-4d6d-85ca-f77623af7510" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Yiran (Ryan) Pang<br />
          VP of Technology
          </div>
          <div className="ourteam_selfintro">
          Hi, I’m Yiran (Ryan) Pang, a senior at Issaquah High School for the 2024 – 2025 school year. I'm passionate about outdoor activities such as skiing, fishing, hiking and playing tennis. Ever since joining IB in 2022, I've been proactively contributing my passions and skills. I established the IB club as the President at my high school to spread awareness and recruit volunteers. Besides attending weekly volunteering activities, I created the first Nordic ski camps at IB by partnering with a local ski resort. Moreover, I developed and maintained the new IB website, which drives the daily operation of IB by critical functions like event-signing up, service hours tracking, and past events recording. As VP of Technology, I'll continue to improve the functions of IB website, develop the strategy and build a tech team to drive multi-platforms communications, and cooperate with other student organizations in my high school to drive the awareness of IB.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FElla%20Chang.JPG?alt=media&token=244f1af4-d9cb-41dc-a0bc-655c2222dc01" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Ella Chang<br />
          VP of Operations & Communications
          </div>
          <div className="ourteam_selfintro">
          Hi! I’m Ella Chang, a rising Senior at Newport High School for the 2024 – 2025 school year. I have been with IB since 2022, and it has been an amazing journey. I started the art group shortly after I joined this wonderful organization to create an empowering environment where kids with special needs can express themselves creatively and develop their social skills. Over the past couple of years, it has been incredibly rewarding to see the progress and growth of each kid in the art group.
As the VP of Operations & Communications, I lead the planning and implementation of our strategic initiatives, and drive our communications efforts to ensure that our mission and the inspiring stories of our participants reach an even wider audience. I am committed to bringing creative ideas and my organizational skills to help International Buddy make an even bigger impact to the communities we serve.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FMax%20Li.JPG?alt=media&token=44cdd503-2244-4e7c-8e8e-72966e45b5a8" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Max Li<br />
          VP of Community Outreach
          </div>
          <div className="ourteam_selfintro">
          Hello, my name is Max Li, and I will be a senior at the International School for the 2024-2025 school year. I am very excited to have the chance to serve as VP of Community. I have been involved with IB for around three years and have witnessed its growth and positive impact on our community.
In my new role, I aim to continue expanding this organization and fostering a welcoming and safe environment for all Buddies. My goals include organizing events that bring together our community, creating opportunities for buddies and volunteers , and ensuring everyone feels valued and supported. I look forward to working with all of you to make IB even more vibrant and impactful.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FValerie%20Wang.JPG?alt=media&token=36bb8123-b54f-4b6e-a80b-406e030d0ac1" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Valerie Wang<br />
          VP of Development
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Valerie, and I go to International School. I am a rising Senior for the 2024 - 2025 school year. Ever since I joined IB in 2022, I have experienced countless memorable moments and have met incredible people. As the VP of Development, my role is to run fundraisers and help the organization grow financially. I hope to collaborate and create more fun activities and am looking forward to serving you as your VP of Development!
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FArthur%20Gao.JPG?alt=media&token=585e00b3-b1a5-4258-8542-88b0baf634a6" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Arthur Gao<br />
          VP of Events
          </div>
          <div className="ourteam_selfintro">
          Hi, I’m Arthur Gao, a member of the class of 2025 at Issaquah High School. I joined IB in 2022, initially serving as a swimming coach and later advancing to the role of swimming team lead at Issaquah. Currently, for the 2024-2025 year, I hold the position of Vice President of Events. In this role, I am dedicated to planning and overseeing all of IB’s events—be it yard sales, fundraising parties, athletic meets, and more—to ensure they run smoothly and provide the best possible experience for our neurodivergent children. I firmly believe that children with special needs should have equal access to all opportunities, and I am deeply committed to making this a reality.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FSean%20Choudhury.JPG?alt=media&token=4386d1d8-fb3f-43c9-86dc-19fa59313b45" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Sean Choudhury<br />
          VP of Finance
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Sean Choudhury. I am a current Senior at International School for the 2024-2025 year. Having joined International buddy for over two years, I can't be more excited to be your VP of Finance. I've loved helping the kids as a swimming coach and enjoyed spending time with them over the summer. As VP of Finance, I will collaborate and organize fundraising activities for the organization, and I am dedicated to allocating funds to provide each kid with an enjoyable experience.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FNathan%20Guan.JPG?alt=media&token=0568d420-5a49-474f-9183-4d2505192430" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Nathan Guan<br />
          VP of Career Training & Tutoring
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Nathan Guan, a Senior at Lake Washington High School for the 2024-2025 year. I am passionate about helping individuals with special needs and advocating for neurodiversity. Living with ADHD myself, I understand the challenges many face and am dedicate to fostering an inclusive environment for all.
My journey with IB began with playing basketball with kids, but it has since grown into creating various opportunities and events. As the Vice President of Career Training and Tutoring, I focus on online tutoring and career readiness training. I have also collaborated with the coding team to develop a Neurodiversity social app, which we will be beta testing during our summer tutoring program.
I am excited to continue supporting the special needs community and helping it thrive.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FAndrew%20Kim.jpg?alt=media&token=0223c30d-769e-40d8-8b76-e2f97ed8bf27" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Andrew Kim<br />
          VP of Sustainability
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Andrew Kim, and I am a Senior at Issaquah High School for the 2024-2025 school year. I joined IB in 2022 as a sophomore, and this organization has allowed me to grow and learn the values of giving back to the community. As the VP of Sustainability role, I'm looking forward to serving our International Buddies family with ways to reduce waste, decrease carbon output, recycle, and contribute in ways to be more environmentally sustainable.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FEli%20Hou.JPG?alt=media&token=c4782724-cfcd-489f-8d5d-4c74b8d205d8" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Eli Hou<br />
          VP of Administration
          </div>
          <div className="ourteam_selfintro">
          Hi my name is Eli Hou and I attend International School. I joined International Buddy in the summer of 8th grade during the pandemic and have really enjoyed working with the kids. My time at International Buddy has taught me a lot and as the VP of administration, I will be able to help International Buddy flourish. As VP of administration, I will be in charge of creating activities and organizing 1 on 1s for buddies and volunteers. If anyone is interested in 1 on 1s or creating new activities, please contact me.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FAndrew%20Liu.JPG?alt=media&token=2bfd93a7-92fc-4a3a-a3de-cc88c2c21be4" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Andrew Liu<br />
          Outreach of Mercer Island
          </div>
          <div className="ourteam_selfintro">
          Hello, my name is Andrew Liu, and I am a senior at Mercer Island High School for the 2024-2025 school year. I have participated in the Swimming Program of International Buddies in this past year, and gained memorable experiences while teaching/playing with the kids in the water. As outreach, I spread awareness and grow the presence of International Buddies in Mercer Island Area and recruit new volunteers for one on one tutoring for kids in Mercer Island.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FYanni%20Liang%20(Annie).JPG?alt=media&token=aa3d63a1-437a-4058-8658-2f97771a4fff" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Yanni Liang (Annie)<br />
          Director of Social Communications
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Annie, and I am a senior at Forest Ridge. I have been working with International Buddy for about a year, volunteering for a variety of activities. Currently, I am Director of Social Communications. I lead the video group, creating and publishing short videos onto various platforms to increase International Buddy’s online presence. I hope to be able to inform more people about International Buddy.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FElaine%20Sin.jpg?alt=media&token=4bd2a1db-646e-4910-9954-0a72ea19c1fb" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Elaine Sin<br />
          Art Captain
          </div>
          <div className="ourteam_selfintro">
          Hello, my name is Elaine Sin and I am a senior at Bellevue High School for the 2024-2025 school year. I love drawing, as I grew up in my parents' art studio and have pursued it my entire life. Starting with watercolor painting in elementary school and working with various mediums such as pastel, graphite, acrylic paint, and oil paint, I enjoyed all of them, especially acrylic. I first joined IB as a volunteer in Spring 2024 in hopes that I could share my passion with the drawing group. As the current leader of the IB drawing group, I am honored to be able to help kids express themselves artistically. This summer, and continuing on, I've been experimenting with different art projects and methods that best suit neurodivergent needs. I've learned a lot so far and still am learning. I am dedicated to our art program and am excited to keep empowering kids through their creative side!
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FAiden%20Li.JPG?alt=media&token=f52ac43d-adf9-403d-abb8-bf7012df5a02" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Aiden Li<br />
          Reading Club Captain
          </div>
          <div className="ourteam_selfintro">
          My name is Aiden Li, and I'm a junior at the Overlake School in Redmond for the 2024-2025 year. I am one of the leaders of Reading Club, where we share the joys of reading with the wonderful children that we collaborate with. Over the Reading Club's two-year history, we have enjoyed a rich diversity of literature: classic favorites like Charlotte's Web, intriguing mysteries with Spy School, educational biographies such as Who Was Neil Armstrong?, and unforgettable humor like Diary Of A Wimpy Kid. It's been rewarding to watch these children develop into passionate readers and critical thinkers, and I hope to invite more kids to join the family of our reading club.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FChanning%20Huang.JPG?alt=media&token=35385f04-3dcc-4228-9b9f-a401fa823f0c" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Channing Huang<br />
          Reading Club Captain
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Channing Huang, and I am a sophomore at International for the 2024-2025 year. I joined IB in 2022 as a 7th grader, and it has been my honor working with the kids under this program. As one of the leaders for the book club program, which involves meeting the kids bi-weekly to discuss a book, I work with the kids through immersive activities designed to boost their literacy skills. It has been incredibly fulfilling to see the kids grow their skills, and I look forward to seeing these kids under the program succeed.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FAlvin%20Chang.JPG?alt=media&token=f319e158-0b93-4e96-aa54-338805002d75" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Alvin Chang<br />
          Special Olympics Captain
          </div>
          <div className="ourteam_selfintro">
          I'm Alvin, a junior at Redmond High School for the 2024-2025 school year. As the leader of the Special Olympics Team, I've loved spending time with athletes to not only work on and improve their sport performance, but also to get to know them and their colorful personalities. Throughout swimming, basketball, and track, I've watched athletes grow and learn in every moment they can; not only in speed and power, but in their abilities to push themselves and power through. I hope to bring a positive influence and the qualities of a good sported athlete to everybody!
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FIvan%20Pong.JPG?alt=media&token=541d1f21-cdea-440b-9d76-eb90b6b1a5ac" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Ivan Pong<br />
          Chess Captain
          </div>
          <div className="ourteam_selfintro">
          Hello, my name is Ivan Pong and I am a junior at International School for the 2024-2025 year.  I joined IB since 2023.  I am very excited and grateful to have the opportunity to take the role of becoming the Chess co-Captain. I have enjoyed playing chess since I was in kindergarten, and I’m always up for the next chess match.  Joining IB has been a great experience.  It has been wonderful to meet so many amazing, caring, and talented people.  It would be an honor for me to continue helping kids sharpen their chess skills, while providing a comfortable and welcoming environment, where they can build their confidence and bond with their chess buddies.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FElliott%20Webb.JPG?alt=media&token=8e36b296-94fc-4253-8c7a-c18c9c4540f6" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Elliott Webb<br />
          Chess Captain
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Elliott, and I'm a junior at Eastlake High School for the 24-25 school year and one of the Chess Team leaders for IB. Being a part of IB has given me the ability to have an impact on my community. I've played chess since a young age, but having the opportunity to play against others and teach chess to them has revitalized my interest in the game. Through the game of chess, I believe children gain a greater understanding of how to approach new situations and think critically. Our events are open to all who want to attend, and we aim to make all who do feel welcome.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FDerek%20Li.JPG?alt=media&token=c7d79803-e069-4b2a-8b7a-48401c844b00" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Derek Li<br />
          Basketball Captain
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Derek Li. I am a sophomore at Overlake for the 2024-2025 year, and joined IB in 2023. Ever since, this program has allowed me to build many fond memories and my experience here has been eventful and exciting. As a new leader of the basketball team, I aim to use my 8 years of experience with the sport to provide our younger friends with an enriching and fun learning experience.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FJennifer%20Xue.JPG?alt=media&token=2c5633af-bbe8-42c9-aead-5a143ef28bd2" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Jennifer Xue<br />
          Basketball Captain
          </div>
          <div className="ourteam_selfintro">
          Hello, I’m Jennifer Xue and I’m a sophomore at The Overlake School for the 2024-2025 year. I am a volunteer and a captain of the basketball team. I have played basketball for over six years and have volunteered at IB for the past year. I’m excited to keep working with the kids and seeing them grow to become their best selves.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FCloris%20Wang.JPG?alt=media&token=702affb3-be0b-453d-8fa1-4259dd0c20a3" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Cloris Wang<br />
          Swimming Captain - Bellevue
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Cloris Wang, and I am a freshman at Bellevue High School for the 2024-2025 school year. When I first encountered International Buddies in 2023, never could I have imagined the incredible impact that this organization will have on me. I am honored to have this opportunity to work with kids who continue to inspire me with their creativity and determination. I look forward to continuing to support this organization with my new role—swim lead for BAC.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FAlex%20Kang.JPG?alt=media&token=805cf6c6-2de8-472c-8c66-15db7187b510" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Alex Kang<br />
          Swimming Captain - Issaquah
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Alex Kang, a sophomore at Bellevue High School for the 2024-2025 school year. Since joining IB in 8th grade, I've dedicated myself to creating a supportive community for neurodivergent children. This year, I’m responsible for leading the swim activities at Issaquah Swim Division, and I am beyond excited to collaborate with my peers to create an inclusive environment!
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FJulia%20Shang.JPG?alt=media&token=c4ab015e-1973-4cd8-881f-913debff41f3" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Julia Shang<br />
          Swimming Captain - Mercer Island
          </div>
          <div className="ourteam_selfintro">
          Hi! I'm Julia, a junior at Mercer island High School. I joined International Buddy at the start of 2023, and have been incredibly grateful to its many experiences and interactions. As swim lead of Mercer island, I plan to expand the program, as well as foster passion and confidence in and out of the water. 
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FEmily%20Zheng.JPG?alt=media&token=aa5636f9-ba80-4bc2-a927-63346180857c" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Emily Zheng<br />
          Badminton Captain
          </div>
          <div className="ourteam_selfintro">
          Hi, I’m Emily Zheng and I will be a sophomore for the 2024-25 school year. I’ve been a volunteer for International Buddy since 2019, but started my role as Badminton Lead only this year. Through badminton, a well-beloved sport within our community, we are able to socially interact with kids who are uncomfortable or unable to do so through other methods while simultaneously fostering partnership and teamwork between them. Additionally, I am too able to bring my past experiences with badminton—relatively extensive training and competitive play—to the table to further encourage growth. 
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FTimothy%20Liao.JPG?alt=media&token=b3ad0cec-fb64-4ee3-8a9e-b6155e05e7a1" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Timothy Liao<br />
          Tennis Captain
          </div>
          <div className="ourteam_selfintro">
          Hi, I'm Timothy Liao, a Mercer Island High School sophomore for the school year of 2024-2025. I have been volunteering with IB since last year and cherish this opportunity to share my passion for tennis. As the tennis team leader, I hope to not only continue all the fun from before but also reach out to any kid who wants to play. Hope to see you on the court!
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FAlexandra%20Deschenes.JPG?alt=media&token=682b7296-7413-4aff-905d-188acdf9a049" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Alexandra Deschenes<br />
          Study Group Captain
          </div>
          <div className="ourteam_selfintro">
          Hello, I'm Alexandra Deschenes and I will be a sophomore at Interlake High School for the 2024-2025 year. I have been a part of IB since 2022, and am honored by the opportunity to lead its one-on-one tutoring program. During my time in the organization as a volunteer for a variety of activities, I've been able to get to know the participants and learn about how to best help them succeed. I hope that the one-on-one tutoring program will be able to help them develop their interpersonal skills, as well as their various subject-related skills.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FCelena%20Zhang.jpg?alt=media&token=12ec2592-69f9-4445-b8fa-7ed0fb1b4edf" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Celena Zhang<br />
          Issaquah Activity Captain
          </div>
          <div className="ourteam_selfintro">
          I'm Celena Zhang, a sophomore at Mount Si High School for the 2024-25 school year. Since joining IB in 2022, I've gained valuable experience by participating in a variety of activities, mainly playground time, art, and reading. It's so fun to play with all the kids in a free and open environment, which has been one of the most rewarding aspects of my involvement. I've also successfully recruited many new members to our organization and am excited to continue helping others connect with the resources they need and having fun in the process. 
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container2">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FIsabella%20Guan.JPG?alt=media&token=a4607395-517a-4440-bf15-6dcd393e17ed" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Isabella Guan<br />
          Director of Career Training & Tutor
          </div>
          <div className="ourteam_selfintro">
          Hello, I'm Isabella Guan, Director of Career Training and Tutoring at IB. I am dedicated to empowering individuals with Autism and advocating for neurodiversity. My focus is on collaborating with the drawing team to publish books and leveraging AI to enhance our career training skillset.
With a passion for creativity and technology, I aim to provide innovative and effective training programs that help our community members thrive in their careers. I am excited to contribute to the growth and success of our neurodiversity community through these initiatives.
          </div>
        </div>
      </div>

      <Line color="--green-color" width="100%" />
      <div className="ourteam_container1">
        <div className="ourteam_photo">
          <img src="https://firebasestorage.googleapis.com/v0/b/internationalbuddy2018.appspot.com/o/our_team%2F2024%2FKaicheng%20Shen.JPG?alt=media&token=62f17c21-6f7f-4b40-9b97-a125694474e8" alt="self" />
        </div>
        <div className="ourteam_content">
          <div className="ourteam_name">
          Kaicheng Shen<br />
          Director of Digital Media
          </div>
          <div className="ourteam_selfintro">
          Hi, my name is Kaicheng Shen. I am a Junior at Skyline High School. I have been participating in International Buddy’s weekly sports activities and special events for three years. As a member, I organized IB’s first Movie Night and will maintain it as a regular IB event. As the Director of Digital Media, I manage IB’s various social media accounts while posting information and marketing events to attract new members and boost awareness. I hope to collaborate with our volunteers to provide an enjoyable and rewarding experience to all IB participants.
          </div>
        </div>
      </div>



    </div>
    </div>
      
  )
}

export default Ourteam